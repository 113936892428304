<template>
	<div class="app-container">
		<aside>网站使用向导.基于
			<a href="https://github.com/kamranahmedse/driver.js" target="_blank">driver.js 实现.</a></aside>
		<el-button icon="el-icon-question" type="primary" @click.prevent.stop="guide">展示向导</el-button>
	</div>
</template>

<script>
	import Driver from 'driver.js' // import driver.js
	import 'driver.js/dist/driver.min.css' // import driver.js css
	import steps from './steps'
    export default {
        name: "Guide",
		data() {
			return {
				driver: null
			}
		},
		mounted() {
			this.driver = new Driver()
		},
		methods: {
			guide() {
				this.driver.defineSteps(steps);
				this.driver.start()
			}
		}
    }
</script>

<style scoped>

</style>
